.select-country-wrapper {
  .select-country-title {
    font-size: 28px;
    font-weight: bold;
    color: #FFFFFF;
    margin: 95px 0 41px 24px;
  }
  .select-country-items {
    padding: 0 12px;
    margin-bottom: 62px;
    .item-checkbox {
      display: flex;
      height: 52px;
      background: rgba(255,255,255,0.1);
      border-radius: 16px;
      margin-bottom: 16px;
      padding-left: 10px;
      --gap: 16px;
      &.adm-checkbox {
        .adm-checkbox-icon {
          border-color: #FF8130;
        }
        &.adm-checkbox-checked .adm-checkbox-icon {
          background: #FF8130;
          border-color: #FF8130;
        }
      }

    }
  }

}