html {
  font-size: 16px;
  overflow-y: auto;
  height: 100%;
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  background-color: #171619;
  color: #fff !important;
  user-select: none;
}
* {
  box-sizing: border-box;
}

body {
  font: 1rem 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', Arial, sans-serif;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}
html, body, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}
body.bb-overflow-hidden {
  overflow: hidden !important;
  height: 100vh;
}