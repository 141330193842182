.list-wrapper {
  .container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    .side {
      .adm-side-bar {
        --width: 119px;
        --adm-color-background: rgba(34, 33, 38, 1);
      }
      .adm-side-bar-items {
        text-align: center;
      }
      .adm-side-bar-item {
        padding: 12px;
        color: #fff;
        justify-content: center;
        border-radius: 22px 0 0 22px;
        .adm-badge-wrapper {
          width: 100%;
        }
        .adm-side-bar-item-title {
          width: 100%;
          position: relative;
          padding-left: 2px;
          word-break: break-all;
        }
        &.adm-side-bar-item-active {
          .adm-side-bar-item-highlight {
            width: 24px;
            height: 24px;
            left: -12px;
            top: -4px;
            background-color: transparent;
            background-image: url('../../assets/icons/active-icon.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .main-wrapper {
      background-color: rgba(34, 33, 38, 1);
      width: 100%;
    }
    .main {
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px;
      flex: 1;
      min-height: 95px;
      .card {
        margin-bottom: 14px;
        &.margin-none {
          margin-right: 0 !important;
        }
      }
    }
  }

}