.bb-tab-bar {
  width: 100%;
  height: calc(64px + constant(safe-area-inset-bottom));
  height: calc(64px + env(safe-area-inset-bottom));
  display: flex;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #171619;
  z-index: 2;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .item {
    text-align: center;

    &.active {
      .icon.home {
        background-image: url('../../assets/home-active.png');
      }
      .icon.mine {
        background-image: url('../../assets/mine-active.png');
      }
    }

    .icon {
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;
      &.home {
        background-image: url('../../assets/home.png');
      }
      &.mine {
        background-image: url('../../assets/mine.png');
      }
    }

  }

}