@import 'src/styles/mixin.scss';

.withdraw-wrapper {
  padding: 16px 18px 0 18px;

  .card {
    padding: 14px 0 17px 14px;
    border-radius: 18px;
    background: #272833;
    overflow: hidden;
    position: relative;
    .blur-block {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      .left {
        @include w(119px);
        height: 42px;
        background: #385F72;
        filter: blur(29px);
      }
      .center {
        @include w(149px);
        height: 20px;
        background: #714847;
        filter: blur(29px);
      }
      .right {
        @include w(113px);
        height: 55px;
        background: #3E416B;
        filter: blur(29px);
      }
    }
    .content {
      position: relative;
      z-index: 1;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .amount-wrapper {
      margin-top: 18px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .withdraw-content {
    .amount-input-wrapper {
      border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      padding: 8px 0 8px 0;
      margin-top: 13px;
      .extra-tips {
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
      }
    }
    .amount-input {
      display: flex;
      align-items: center;
      .bb-input {
        border-bottom: none;
        margin-top: 0;
        padding-bottom: 0;
        flex: 1;
      }
      .total-btn {
        font-size: 16px;
        font-weight: bold;
        color: #FFCC30;
      }
      .total-close-btn {
        width: 17px;
        font-size: 25px;
        display: inline-block;
        text-align: center;
      }
    }
    .input-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 19px;
    }
  }
  .confirm-btn {
    margin-top: 210px;
    width: 100%;
  }

}

@media screen and (max-width: 320px){
  .withdraw-wrapper .confirm-btn {
    margin-top: 130px;
  }
}