.bb-tabs {
  --active-title-color: #fff;
  --active-line-color: rgba(255, 204, 48, 1);
  --fixed-active-line-width: 16px;
  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #171619;
    z-index: 2;
  }
  .adm-tabs-header-mask {
    display: none;
  }
  .adm-tabs-header {
    border-bottom: none;
  }
  .adm-tabs-tab {
    font-size: 14px;
    color: rgba(94, 94, 97, 1);
    &.adm-tabs-tab-active {
      font-weight: bold;
      font-size: 16px;
      color: var(--active-title-color);
    }
  }
  
}