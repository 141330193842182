.bb-marquee-container {
  .bb-marquee-item {
    background: rgba(255,255,255, .14);
    color: #fff;
    padding: 4px 20px;
    font-size: 14px;
    border-radius: 13px;
    margin-right: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

}