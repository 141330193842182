.bb-verify-code-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  padding: 8px 0 8px 0;
  margin-top: 24px;
  .bb-input {
    border-bottom: none;
    margin-top: 0;
    padding-bottom: 0;
  }
}