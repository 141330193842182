.bb-agree-checkbox {
  padding: 0 22px 0 22px;
  margin-top: 14px;
  --font-size: 12px;
  --icon-size: 10px;
  --gap: 6px;

  &.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
    background: #fff;
    border-color: #fff;
    svg path {
      fill: #000;
    }
  }
  .adm-checkbox-content {
    font-weight: 400;
  }
  .link {
    color: rgba(255, 69, 71, 1);
  }
}


.contract-fake-container {
  z-index: 1000;
  .full-screen-children {
    padding-bottom: 65px;
    background: #171619;
    z-index: 1001;
    overflow-y: auto;
  }
  .contract-fake-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 45px;
    background: #171619;
    z-index: 1002;
  }
}

