
.detail-wrapper {
  .box-container {
    text-align: center;
    padding: 30px 24px 0 24px;
    .title {
      font-size: 14px;
      margin-bottom: 28px;
    }
    .amount-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .bb-coin-large {
        font-size: 24px;
        font-weight: 500;
        margin-left: 4px;
        margin-right: 6px;
      }
      .origin-amount {
        text-decoration: line-through;
        font-size: 12px;
        color: #928C96;
      }
    }
    .box-image {
      width: 216px;
      height: auto;
      margin: 20px auto 16px auto;
    }
    .tips {
      padding: 2px 0 2px 17px;
      font-size: 14px;
      font-weight: 400;
      background: #313034;
      border-radius: 13px;
      margin-bottom: 22px;
      --height: auto;
      border: none;
    }

    .try-free {
      font-size: 14px;
      font-weight: bold;
      color: #FF8130;
      text-decoration: underline;
      margin-top: 14px;
    }

  }
}