.box-detail-product-items-wrapper {
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  .card {
    margin-bottom: 10px;
    &.margin-none {
      margin-right: 0 !important;
    }
  }
}