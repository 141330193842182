@import "src/styles/mixin.scss";

@mixin font10 {
  font-size: 10px;
  transform: scale(0.8);
  transform-origin: left;
}

.bb-item-card {
  width: 110px;
  height: 167px;
  background-image: url('../../assets/card-bg-large.png');
  background-position: center;
  background-size: cover;
  color: #fff;
  padding: 8px 7px;
  position: relative;
  &.normal {
    height: 155px;
    background-image: url('../../assets/card-bg-small.png');
    padding: 10px;
  }

  .image-large {
    margin-top: 11px;
    width: 96px;
    height: 72px;
  }
  .image-normal {
    //width: 69px;
    //height: 92px;
  }

  .flag {
    background-image: url('../../assets/flag.png');
    background-position: center;
    background-size: cover;
    width: 72px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .text {
      font-size: 11px;
      transform: scale(0.9);
      display: inline-block;
      font-weight: bold;
      font-style: italic;
    }
    
  }

  .content {
    margin-top: 5px;
    .title {
      font-size: 12px;
      line-height: 14px;
    }
    .bb-coin {
      display: flex;
    }
    .current-price {
      font-size: 14px;
      color: rgba(255, 159, 0, 1);
      font-style: italic;
      font-weight: bold;
      margin-top: 1px;
      &.normal {
        margin-top: 11px;
      }
    }
    .origin-price {
      @include font10();
      text-decoration: line-through;
      color: rgba(255, 70, 70, 1);
    }
    .quantity {
      @include font10();
      line-height: 15px;
      margin-top: 2px;
    }

  }

}