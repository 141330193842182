.common-items-wrapper {
  padding-top: 16px;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(255,255,255,.5);
  // &:last-child {
  //   border-bottom: none;
  // }
  .title {
    font-size: 16px;
    font-weight: bold;
    padding: 0 16px;
    .sub-title {
      font-size: 14px;
      font-weight: 400;
      float: right;
    }
  }

  .item-container {
    padding: 17px 14px 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item-info-wrapper {
      display: flex;
      .item-info {
        margin-left: 10px;
        .item-name {
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: bold;
          max-width: 200px;
          word-break: break-word;
        }
        .item-price {
          font-size: 14px;
        }
        .item-align-center {
          display: flex;
          align-items: center;
        }

      }
    }

    .open-btn {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .receive-info-area {
    display: flex;
    padding-right: 15px;
    div {
      flex: 1;
    }
  }
  .item-time {
    margin: 7px 0 4px 16px;
    font-size: 12px;
  }
  .item-order-id {
    font-size: 12px;
    margin-left: 16px;
  }
  .deliver-info {
    font-size: 12px;
    margin-left: 16px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }


}