.address-info-wrapper {
  .address-input {
    margin-top: 0;
    padding: 16px 18px;
  }
  .set-default-address {
    padding: 16px 18px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
    display: flex;
    flex-direction: row-reverse;
    .adm-checkbox-content {
      flex: 1;
      padding-left: 0;
      font-size: 14px;
      color: #8C8D92;
    }
    .adm-checkbox-icon {
      border-color: #FF8130;
    }
    &.adm-checkbox.adm-checkbox-checked {
      .adm-checkbox-icon {
        background: #FF8130;
        border-color: #FF8130;
      }
      .adm-checkbox-content {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
    }
  }

}
