.home-items-wrapper {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  .card {
    margin-bottom: 10px;
    &.margin-none {
      margin-right: 0 !important;
    }
  }
}