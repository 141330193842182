.bb-swiper-container {
  width: 100%;
  padding: 0 12px;

  .bb-swiper {
    width: 100%;
    height: 130px;
    background: #171619;
    border-radius: 16px;
    margin: 0 auto;

    .item-image {
      width: 100%;
      height: 100%;
    }

  }
}

