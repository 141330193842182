.setting-wrapper {
  .avatar {
    width: 100%;
    height: 203px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 116px;
      height: 108px;
      background-image: url('../../assets/setting.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .setting-info {
    font-size: 16px;
    font-weight: bold;
    div {
      padding: 10px 24px;
      min-height: 55px;
      border-bottom: 1px solid rgba(255, 255, 255, .5);
      display: flex;
      align-items: center;
      word-break: break-word;
    }
  }
}