.public-style {
  display: inline-block;
  width: calc(100% / 3);
  height: 2px;
  position: relative;
}
.public-style-pseudo {
  content: " ";
  width: 4px;
  height: 4px;
  background-color: #FF8130;
  position: absolute;
  top: -1px;
  border-radius: 50%;
}

.item-in-box {
  margin-top: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    height: 25px;
    line-height: 25px;
    background: #5E3823;
    border-radius: 25px;
    color: rgba(255, 129, 48, 1);
    font-size: 14px;
    padding: 0 19px;
    margin: 0 5px;
    white-space: nowrap;
  }
  .left {
    @extend .public-style;
    background: linear-gradient(90deg, rgba(255,129,48,0) 0%, #FF8130 100%);
    &::after {
      right: 0;
      @extend .public-style-pseudo;
    }
  }
  .right {
    @extend .public-style;
    background: linear-gradient(-90deg, rgba(255,129,48,0) 0%, #FF8130 100%);
    &::after {
      left: 0;
      @extend .public-style-pseudo;
    }
  }
}
.item-in-box-note {
  font-size: 12px;
  color: #A4A4A6;
  margin-top: 10px;
}