.bb-text-area {
  &.normal {
    border-bottom: 1px solid rgba(255,255,255,.14);
    padding-bottom: 10px;
    margin-top: 32px;
  }
  &.block {
    background: rgba(255,255,255, .14);
    border-radius: 12px;
    min-height: 80px;
    padding-bottom: 6px;
    .adm-text-area-element {
      padding: 10px 10px 6px 10px;
    }
  }
  .adm-text-area-element {
    --placeholder-color: #8C8D92;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }
  .adm-text-area-count {
    font-size: 14px;
    color: #ffffff;
    margin-right: 6px;
  }
}