.select-address-wrapper {
  .have-address-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    min-height: 70px;
    border-bottom: 1px solid rgba(255,255,255,.5);
    .address-left {
      display: flex;
      align-items: center;
      .address-left-info {
        margin-left: 12px;
        font-size: 14px;
        font-weight: 400;
        .base-info {
          font-size: 16px;
          span:first-child {
            margin-right: 11px;
          }
        }
      }
    }
  }
  .address-add-info {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 70px;
    border-bottom: 1px solid rgba(255,255,255,.5);
    .address-add-text {
      margin-left: 13px;
      font-size: 18px;
    }
  }
  .select-address-item {
    padding: 12px 18px 12px 16px;
    font-size: 16px;
    margin-bottom: 2px;
    border-bottom: 1px solid rgba(255,255,255,.5);
    &.last {
      border-bottom: none;
    }

    .select-address-item-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      .adm-image {
        margin-right: 11px;
      }
    }

    .select-address-order-no {
      font-size: 12px;
      margin-top: 7px;
    }

  }
  .select-address-freight {
    font-size: 16px;
    position: fixed;
    bottom: 95px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
  .select-address-item-scroller {
    overflow-y: auto;
  }

}