.cancel-order-alert-modal {
  .bb-modal-body {
    padding: 18px 10px 20px 10px;
  }
  .cancel-order-alert-title {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
  }
  .btn-group {
    margin-top: 34px;
    display: flex;
    justify-content: space-between;
    .bb-button {
      flex: 1;
      &:nth-child(2) {
        margin-left: 18px;
      }
    }
  }

}