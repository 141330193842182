.bb-withdraw-result {
  text-align: center;
  .withdraw-result-title {
    font-size: 16px;
    font-weight: 500;
    margin: 14px 0 48px 0;
  }

  .success-feedback {
    margin-top: 34px;

    .tips {
      max-width: 221px;
      font-size: 18px;
      font-weight: bold;
      margin: 18px auto 16px auto;
    }
  }
  .border-line {
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,.14);
  }
  .contact {
    text-align: center;
    margin-top: 18px;
    .title {
      font-size: 16px;
      margin-bottom: 8px;
    }
    .email {
      font-size: 14px;
      color: #FF8130;
    }
  }
}