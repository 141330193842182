.transactions-wrapper {
  padding-top: 4px;
  .items {
    border-bottom: 1px solid rgba(255,255,255,.5);
    padding: 16px 18px;
    display: flex;
    justify-content: space-between;
    &:last-child {
      border-bottom: none;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .time {
      font-size: 14px;
    }
    .amount {
      font-size: 16px;
      display: flex;
      align-items: center;
      .reverse-coin {
        flex-direction: row-reverse;
        vertical-align: middle;
        .bb-coin-icon {
          margin-left: 3px;
          margin-right: 0;
        }
      }
    }

  }
}