.address-wrapper {
  padding: 0 12px 50px 12px;
  margin-top: 16px;
  .add-container {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 20px;
    .add-icon {
      margin-right: 10px;
    }
  }
  .select-address-btn-wrapper {
    //bottom: 0;
    //height: calc(79px + constant(safe-area-inset-bottom));
    //height: calc(79px + env(safe-area-inset-bottom));
    //background: #171619;
  }

}