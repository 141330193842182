.bb-input {
  border-bottom: 1px solid rgba(255,255,255,.14);
  padding-bottom: 10px;
  margin-top: 32px;
  &.adm-input {
    --placeholder-color: #8C8D92;
  }
  .adm-input-element {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }
}