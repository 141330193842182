.bb-payment-modal {
  .bb-modal-body {
    padding: 19px 0 20px 0;
    text-align: center;
    font-size: 14px;
    .payment-modal-title {
      font-size: 22px;
      font-weight: bold;
      font-style: italic;
      margin-bottom: 8px;
    }
    .box-name {
      font-size: 16px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: -10px;
    }
    .payment-modal-count-down {
      margin-top: -15px;
    }
    .payment-modal-price {
      margin: 10px 0 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .payment-modal-pic {
      width: 150px;
      height: 150px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &.box {
        background-image: url("../../assets/box.png");
      }
      &.freight {
        background-image: url("../../assets/freight.png");
      }
    }
    .payment-modal-btn {
      width: 205px;
      height: 38px;
      &>span {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}