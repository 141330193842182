.withdraw-record-wrapper {
  margin-top: 20px;
  padding: 0 12px;

  .record-item-card {
    padding-top: 12px;
    padding-bottom: 12px;
    background: rgba(255,255,255,0.1);
    border-radius: 16px;
    margin-bottom: 12px;

    .apply-time {
      font-size: 16px;
      font-weight: 400;
      padding-left: 12px;
    }
    .border-line {
      width: 100%;
      height: 1px;
      background: rgba(255,255,255,.5);
      margin: 12px 0 16px 0;
    }

    .amount-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      margin-bottom: 12px;
      .withdraw-record-coin {
        font-size: 22px;
        font-weight: bold;
      }
      .status {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .extra-info {
      padding: 0 12px;
      .title {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 400;
      }
      .process-time {
        font-size: 12px;
        font-weight: 400;
      }
    }

  }



}