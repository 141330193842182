@import "../../styles/mixin";

.activity-b-container {
  height: 100vh;
  width: 100vw;
  background-color: rgb(192,207,253);
}

.activity-b {
  background-image: url("../../assets/a-2.png");
  width: 100%;
  height: px2rem(790);
  @include bg;
  position: relative;

  .link {
    width: px2rem(277);
    height: px2rem(52);
    display: inline-block;
    position: absolute;
    top: px2rem(289);
    left: 50%;
    transform: translateX(-50%);
  }
}