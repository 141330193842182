.wait-pickup-wrapper {
  padding-top: 16px;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 16px;
    margin-bottom: 10px;
  }
  .items-wrapper {
    margin-bottom: 16px;
    padding: 0 14px 0 16px;
    .pickup-checkbox {
      --gap: 0;
      --icon-size: 18px;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      .adm-checkbox-icon {
        border-color: #FF8130;
      }
      &.adm-checkbox-checked .adm-checkbox-icon {
        background-color: #FF8130;
        border-color: #FF8130;
      }
      .adm-checkbox-content {
        display: flex;
        align-items: center;
      }

      .item-info {
        margin-left: 10px;
        .item-name {
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: bold;
          max-width: calc(100vw - 175px);
          word-break: break-word;
        }
        .item-price {
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }
    }
    .item-time {
      margin: 7px 0 4px 0;
      font-size: 12px;
    }
    .item-order-id {
      font-size: 12px;
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #171619;
    height: calc(56px + constant(safe-area-inset-bottom));
    height: calc(56px + env(safe-area-inset-bottom));
    .bb-button {
      flex: 1;
      &:first-child {
        margin-right: 40px;
      }
    }
  }

}