@import "src/styles/mixin.scss";

.mine-account-card {
  @include w(323px);
  height: 110px;
  margin: 14px auto 0;
  padding: 14px 0 12px 0;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, #272833 0%, rgba(39,40,51,0) 100%);
  border-image: linear-gradient(180deg, rgba(154, 111, 111, 0.7), rgba(255, 255, 255, 0)) 1 1;
  position: relative;
  border: 1px solid rgba(255,255,255,.3) !important;
  border-bottom: none !important;

  .item-wrapper {
    display: flex;
    justify-content: space-around;
    z-index: 1;
    position: relative;
  }

  .blur-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    .left {
      @include w(114px);
      height: 45px;
      background: #385F72;
      filter: blur(25px);
    }
    .center {
      @include w(133px);
      height: 15px;
      background: #714847;
      filter: blur(25px);
    }
    .right {
      @include w(114px);
      height: 56px;
      background: #3E416B;
      filter: blur(25px);
    }
  }

  .item {
    text-align: center;
    flex: 0 1 0;
    .icon {
      width: 48px;
      height: 48px;
      background-color: red;
      border-radius: 50%;
      margin-bottom: 8px;
    }
    .text {
      text-align: center;
      //word-break: break-word;
      //min-width: 68px;
      white-space: nowrap;
    }
  }

}