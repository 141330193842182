.login-wrapper {
  padding: 0 24px;
  .title {
    margin-top: 95px;
    margin-bottom: 105px;
    .start-login {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .tips {
      font-size: 14px;
    }
  }
  .register-btn-group {
    margin: 60px auto 0 auto;
    display: flex;
    .bb-button {
      flex: 1;
      height: 44px;
      &.login-btn {
        height: auto;
      }
      &.skip-btn {
        margin-right: 31px;
      }
    }

  }
  .switch-input-mode {
    font-size: 16px;
    font-weight: 400;
    color: #FFCC30;
    text-align: right;
    margin-top: 22px;
    text-decoration: underline;
  }
  .agree-checkbox {
    --font-size: 12px;
    --icon-size: 10px;
    --gap: 6px;
    margin-top: 12px;
    .link {
      color: rgba(255, 69, 71, 1);
    }
  }
  .btn-wrapper {
    text-align: center;
    .skip-text {
      font-size: 16px;
      font-weight: 600;
      margin-top: 22px;
    }
  }
}