@import "../../styles/mixin";

.activity-a-container {
  height: 100vh;
  width: 100vw;
  background-color: #7B8EF4;
}

.activity-a {
  background-image: url("../../assets/a-1.png");
  width: 100%;
  height: px2rem(790);
  @include bg;
  position: relative;

  .link {
    width: px2rem(252);
    height: px2rem(50);
    display: inline-block;
    position: absolute;
    bottom: px2rem(82);
    left: 50%;
    transform: translateX(-50%);
  }
}