@use "sass:math";

@mixin w($width) {
  width: math.div($width, 375px) * 100%;
}

@function p2p($number) {
  @return math.div($number, 375px) * 100%;
}

@mixin bg {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
}

@function px2rem($px) {
  @return calc(#{$px}rem / 14)
}
