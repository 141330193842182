.contact-wrapper {
  .contact-us {
    margin: 14px 0 8px 18px;
    font-size: 16px;
    font-weight: bold;
  }
  .item-wrapper {
    height: 74px;
    padding-left: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .value {
      font-size: 14px;
    }
  }
}