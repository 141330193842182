.common-order-items {
  padding: 14px 16px 18px 16px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgba(255,255,255,.5);

  .order-items-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
  }
  .order-items-wrapper {
    position: relative;
    margin-bottom: 20px;

    .order-items-info {
      display: flex;
      justify-content: space-between;
      .mt11 {
        margin-top: 11px;
      }
      .order-items-price {
        font-size: 16px;
        display: unset;
      }
      .order-items-image {
        display: flex;
        .adm-image {
          margin-right: 11px;
        }
        .item-name {
          max-width: 200px;
          word-break: break-word;
        }
      }
    }

    .btn-group {
      position: absolute;
      right: 0;
      bottom: 0;
      .bb-button:first-child {
        margin-right: 12px;
      }
    }
  }

}