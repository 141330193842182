@import 'src/styles/mixin.scss';

.feedback-wrapper {
  padding: 17px 18px 0 18px;
  .input-title {
    font-size: 18px;
    font-weight: bold;
  }
  .feedback-input {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .upload-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .account-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 28px;
  }
  .account-input {
    margin-top: 18px;
  }
  .feedback-img-upload {
    --cell-size: 100px;
    --gap-horizontal: 16px;
    .adm-image-uploader-cell {
      background: #252429;
      border: 1px solid #fff;
      border-radius: 16px;
      .adm-image-uploader-upload-button-icon {
        color: #fff;
      }
      .adm-image-uploader-cell-delete {
        right: 6px;
        top: 6px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        .adm-image-uploader-cell-delete-icon {
          top: 4px;
        }
      }
    }
  }

  .confirm-btn {
    margin-top: 235px;
    width: 100%;
  }

}

@media screen and (max-width: 320px){
  .feedback-wrapper .confirm-btn {
    margin-top: 135px;
  }
}