$color-primary: #FFCC30;
$color-warning: #FF8130;
$bb-font-size-12: 12px;
$bb-font-size-14: 14px;
$bb-font-size-16: 16px;

.bb-button {
  --background-color: #{$color-primary};
  --border-color: #{$color-primary};
  --border-radius: 25px;
  --text-color: #ffffff;
  font-size: #{$bb-font-size-16};
  font-weight: bold;
  white-space: nowrap;
  line-height: 1;
  &.adm-button.adm-button-warning {
    --background-color: #{$color-warning};
    --border-color: #{$color-warning};
    &.adm-button-fill-outline {
      --background-color: transparent;
      --border-color: #{$color-warning};
      --text-color: #{$color-warning};
      --border-width: 2px;
    }
  }
  &.adm-button.adm-button-large {
    font-size: #{$bb-font-size-16};
    font-weight: bold;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &.adm-button.adm-button-mini {
    font-size: #{$bb-font-size-14};
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &.adm-button.adm-button-small {
    font-size: #{$bb-font-size-16};
    padding-top: 9px;
    padding-bottom: 9px;
  }
  &.adm-button.adm-button-fill-outline {
    --border-color: #{$color-primary};
    --text-color: #{$color-primary};
    --border-width: 2px;
    &.adm-button-small {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  &.adm-button.adm-button-loading {
    .adm-button-loading-wrapper {
      height: 1em;
    }
  }

}
.bb-button-container {
  width: 100%;
  &.fixed {
    position: fixed;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
  }
  .bb-button {
    width: 100%;
  }
}