.mine-sub-nav {
  margin-top: 14px;
  padding: 0 20px 0 24px;
  font-size: 14px;

  .sub-nav-item {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 12px;
      }
    }
  }
}