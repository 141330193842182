.bb-open-box {
  background: #171619;
  font-size: 14px;
  text-align: center;

  .wait-open-box-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .wait-open-box-name {
      font-size: 20px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 46px;
    }

    .btn-group {
      margin: 52px 24px 18px 24px;
      display: flex;
      .bb-button {
        flex: 1;
        height: 44px;
        font-size: 14px;
        &:nth-child(2) {
          margin-left: 31px;
        }
      }
    }
    .tips {
      max-width: 251px;
      margin: 0 auto;
    }
  }

  .opened-box-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding-top: 89px;

    .opened-box-product-name {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .adm-image {
      display: inline-block;
      margin: 55px 0 51px 0;
    }
    .tips {
      max-width: 327px;
      margin: 0 auto;
    }
    .opened-btn-group {
      margin: 22px 24px 34px 24px;
      display: flex;
      .bb-button {
        height: 44px;
        font-size: 14px;
        flex: 1;
        &:nth-child(2) {
          margin-left: 31px;
        }
      }
    }
    .close-btn-wrapper {
      margin-bottom: 27px;
      width: 100%;
      text-align: right;
      padding-right: 35px;
      .close-btn {
        width: 34px;
        height: 34px;
        line-height: 28px;
        font-size: 28px;
        display: inline-block;
        text-align: center;
      }
    }

    .open-box-recycle-btn > span {
      display: flex;
      align-content: center;
      justify-content: center;
    }

  }
  
  @media screen and (max-width: 360px){
    .opened-box-container {
      padding-top: 30px;
      .adm-image {
        margin: 20px 0;
      }
      .opened-btn-group {
        margin: 10px 24px;
      }
      .close-btn-wrapper {
        margin-bottom: 0;
      }
    }
    .wait-open-box-container .wait-open-box-name {
      margin-bottom: 16px;
    }

  }



}