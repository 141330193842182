@import "src/styles/mixin.scss";

.public-style {
  position: absolute;
  top: 0;
  filter: blur(50px);
}

.bb-layout {
  padding-top: 24px;
  padding-bottom: 64px;
  position: relative;
  //min-height: 100vh;
  &::before {
    content: " ";
    @extend .public-style;
    left: 0;
    @include w(188px);
    height: 177px;
    background: #1A203D;
    position: absolute;
  }
  &::after {
    content: " ";
    @extend .public-style;
    right: 0;
    @include w(153px);
    height: 190px;
    background: #341A3D;
  }

  .bb-layout-children {
    position: relative;
    z-index: 1;
    padding-top: 20px;
    //padding-bottom: 52px;

    &.nav-bar-hided {
      padding-top: 0;
    }
  }
}