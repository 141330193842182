
@mixin icon($number) {
  width: $number;
  height: $number;
}

.bb-coin {
  display: inline-flex;
  align-items: center;
  &-small &-icon {
    @include icon(14px);
  }
  &-middle &-icon {
    @include icon(18px);
  }
  &-large &-icon {
    @include icon(23px);
  }
  &-icon {
    display: inline-block;
    background-image: url('../../assets/coin.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 3px;
    margin-top: 1px;
  }
}