
.mine-wrapper {
  padding-bottom: 44px;
  .login-info {
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .content {
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;
      max-width: 200px;
      word-break: break-word;
    }
  }
  
}