.bb-category-type {
  .adm-jumbo-tabs-header {
    border-bottom: none;
  }
  .adm-scroll-mask {
    display: none;
  }
  .adm-jumbo-tabs-tab-description {
    background: transparent;
    color: #fff;
    font-size: 14px;
  }
  .adm-jumbo-tabs-tab-title {
    padding: 0;
    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
    .fallback-img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-image: url("../../../assets/horse-default.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .adm-jumbo-tabs-tab-list {
    //padding-left: 43px;
  }
  .adm-jumbo-tabs-tab-wrapper {
    //margin-right: 62px;
    padding: 0;
    width: calc(100% / 3);
  }

}