.top-up-wrapper {
  padding-top: 17px;
  //padding-bottom: 40px;
  .top-content {
    padding: 0 22px;
  }

  .text {
    font-size: 16px;
    font-weight: bold;
  }

  .tips {
    color: #FFCC30;
    font-size: 14px;
    margin-top: 11px;
    display: flex;
    align-items: center;
    .coin {
      width: 12px;
      height: 12px;
      display: inline-block;
      background-image: url('../../assets/coin.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 1px;
    }
  }

  .recommend {
    margin-top: 20px;
    .title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .item-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: calc((100% - 49px) / 3);
        height: 63px;
        background: #302F34;
        border-radius: 10px;
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        line-height: 63px;
        &.active {
          background: #FF8130;
        }
      }
    }

  }

  .pay-btn-group {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin-top: 28px;
    .bb-button {
      margin-bottom: 8px;
    }
  }

}
