.bb-swipe-action {
  --background: transparent;
  margin-bottom: 18px;
  .adm-swipe-action-content {
    background: #323135;
    border-radius: 16px;
    padding: 14px 14px 14px 10px;
  }
  .adm-swipe-action-actions-right {

    .adm-button {
      border-radius: 16px;
      padding: 0;
      &:first-child {
        width: 14px;
        background: transparent;
      }
      &:nth-child(2) {
        width: 60px;
        background-color: rgba(255, 91, 91, 1);
      }
    }
  }
  .bb-swipe-action-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .adm-checkbox {
      flex: 1;
      .adm-checkbox-content {
        padding-right: var(--gap);
      }
    }

    .name-info {
      margin-bottom: 7px;
      font-size: 16px;
      span:first-child  {
        margin-right: 11px;
      }
    }
    .address-info {
      font-size: 14px;
      word-break: break-word;
    }
    .adm-checkbox.adm-checkbox-checked {
      .adm-checkbox-icon {
        background: #FF8130;
        border-color: #FF8130;
      }
    }
  }

  .adm-checkbox-content {
    display: flex;
    align-items: center;
  }
}