.bb-item-recycler {
  font-size: 16px;
  .bb-item-recycler-children {
    padding-top: 18px;
    padding-bottom: calc(36px + constant(safe-area-inset-bottom));
    padding-bottom: calc(36px + env(safe-area-inset-bottom));
    text-align: center;
    .title {
      font-size: 22px;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .bb-item-recycler-container {
      max-height: calc(210px + constant(safe-area-inset-bottom));
      max-height: calc(210px + env(safe-area-inset-bottom));
      overflow-y: auto;
    }
    .bb-item-recycler-content {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      padding: 3px 16px;
      .adm-image {
        margin-right: 16px;
      }
      p {
        display: flex;
        align-items: center;
      }
    }
    .items-cost {
      margin: 25px 0 21px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .agree-checkbox {
      --font-size: 12px;
      --icon-size: 10px;
      --gap: 6px;
      margin-top: 12px;
      .link {
        color: rgba(255, 69, 71, 1);
      }
    }
  }
}