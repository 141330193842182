@import "src/styles/mixin.scss";

.public-style {
  position: absolute;
  top: 0;
  filter: blur(50px);
}

.bb-full-screen-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #171619;
  //height: 100vh;
  &:before {
    content: " ";
    @extend .public-style;
    left: 0;
    @include w(188px);
    height: 177px;
    background: #1A203D;
  }
  &:after {
    content: " ";
    @extend .public-style;
    right: 0;
    @include w(153px);
    height: 190px;
    background: #341A3D;
  }
  .full-screen-children {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}