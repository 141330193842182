.open-box-container {
  width: 216px;
  height: 180px;
  display: inline-block;

  #open-box-canvas {
    transform: scale(1.15);
    transform-origin: bottom;
  }

  .open-box-image {
    width: 216px;
    height: 180px;
    display: inline-block;
    &.hide {
      display: none;
    }
  }
  .show-animate {
    display: initial;
  }
  .hide-animate {
    display: none;
  }
}



