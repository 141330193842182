@import "src/styles/mixin.scss";
.bb-popup {
  .bb-popup-mask {
    background: rgba(0,0,0,.7) !important;
    backdrop-filter: blur(10px);
  }
  .bb-popup-body {
    border-radius: 28px 28px 0 0;
    color: #fff;
    background-color: #272833;
    overflow: hidden;
    .adm-popup-close-icon {
      color: #fff;
      right: 12px;
      top: 12px;
    }
    .blur-block {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      .left {
        @include w(134px);
        height: 83px;
        background: #385F72;
        filter: blur(29px);
      }
      .center {
        @include w(149px);
        height: 46px;
        background: #714847;
        filter: blur(29px);
      }
      .right {
        @include w(134px);
        height: 96px;
        background: #3E416B;
        filter: blur(29px);
      }
    }

    .bb-popup-children {
      position: relative;
      z-index: 1;
    }
  }
}