@import "../../styles/mixin";

.activity-c-container {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(290deg, #FB9090 0%, #FF4141 100%), linear-gradient(176deg, #FB9090 0%, rgba(254, 160, 160, 0) 100%);
}

.activity-c {
  background-image: url("../../assets/a-3.png");
  width: 100%;
  height: px2rem(790);
  @include bg;
  position: relative;

  .link {
    width: px2rem(217);
    height: px2rem(52);
    display: inline-block;
    position: absolute;
    bottom: px2rem(74);
    left: 50%;
    transform: translateX(-50%);
  }
}