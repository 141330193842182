@import "src/styles/mixin.scss";

.bb-modal {
  .adm-center-popup-wrap {
    min-width: 299px;
    div {
      will-change: transform,opacity;
    }
  }
  .bb-modal-mask {
    background: rgba(0,0,0,.7) !important;
    backdrop-filter: blur(10px);
    will-change: opacity;
  }
  .bb-modal-body {
    min-height: 96px;
    background: #272833;
    border-radius: 16px;
    color: #fff;
    width: 299px;
    .close-btn {
      width: 34px;
      height: 34px;
      background: rgba(0,0,0,.7);
      border-radius: 50%;
      font-size: 24px;
      text-align: center;
      position: absolute;
      left: calc(50% - 17px);
      bottom: -64px;
    }
    .bb-modal-content {
      position: relative;
      z-index: 1;
    }
  }

  .blur-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    .left {
      @include w(134px);
      height: 83px;
      background: #385F72;
      filter: blur(29px);
    }
    .center {
      @include w(149px);
      height: 46px;
      background: #714847;
      filter: blur(29px);
    }
    .right {
      @include w(134px);
      height: 96px;
      background: #3E416B;
      filter: blur(29px);
    }
  }
}

@media screen and (max-width: 320px){
  .bb-modal .bb-modal-body .close-btn {
    bottom: -44px;
  }
}